import {
  RESET_STATE,
  SET_EVENT_TYPE,
  SET_COMPETITION,
  SET_EXCH_EVENT,
  FETCH_EVENT_BY_COMPETITION_SUCCESS,
  FETCH_EVENT_BY_COMPETITION_FAILD,
  UPDATE_SECONDARY_MARKETS,
  UPDATE_SECONDARY_MATCH_ODDS,
  UPDATE_SCORECARD,
  UPDATE_FANCY_MARKETS,
  UPDATE_BOOKMAKER_MARKETS,
  UPDATE_TOPIC_URLS,
  SUSPENDED_MARKETS,
  DISABLED_MARKETS
} from './exchangeSportsActionTypes';
import { SelectedObj } from '../../models/ExchangeSportsState';
import { AxiosResponse } from 'axios';
import API from '../../api';
import { EventDTO } from '../../models/common/EventDTO';
import { getSportIdBySlug } from '../../constants/SportsContants';
import SVLS_API from '../../svls-api';

// redux Actions
export const resetExchangeState = () => {
  return {
    type: RESET_STATE,
    payload: {},
  };
};

// EventTypes
export const setEventType = (eventType: SelectedObj) => {
  return {
    type: SET_EVENT_TYPE,
    payload: eventType,
  };
};

// Competitions
export const setCompetition = (competition: SelectedObj) => {
  return {
    type: SET_COMPETITION,
    payload: competition,
  };
};

// Events
const fetchEventByCompetitionSuccess = (result) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: result,
  };
};

const fetchEventByCompetitionFaild = () => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_FAILD,
  };
};

export const setExchEvent = (event: SelectedObj) => {
  return {
    type: SET_EXCH_EVENT,
    payload: event,
  };
};

export const updateTopicUrls = (payload) => {
  return {
    type: UPDATE_TOPIC_URLS,
    payload: payload,
  };
};

const updateTopicUrlsInStore = (dispatch: Function, eventData: any) => {
  // Dispatch topic urls
  const topicUrlPayload = {
    matchOddsBaseUrl: eventData?.markets?.matchOddsBaseUrl,
    matchOddsTopic: eventData?.markets?.matchOddsTopic,
    bookMakerBaseUrl: eventData?.markets?.bookMakerBaseUrl,
    bookMakerTopic: eventData?.markets?.bookMakerTopic,
    fancyBaseUrl: eventData?.markets?.fancyBaseUrl,
    fancyTopic: eventData?.markets?.fancyTopic,
    premiumBaseUrl: eventData?.markets?.premiumBaseUrl,
    premiumTopic: eventData?.markets?.premiumTopic,
  }
  console.log("urls are ", topicUrlPayload)
  dispatch(updateTopicUrls(topicUrlPayload));
}

export const fetchEvent = (
  sportId: string,
  competitionId: string,
  eventId: string
) => {
  return async (dispatch: Function) => {
    try {
      if (eventId !== '' && sportId !== '') {
        var eventDetails = [
          {
            providerId: 'BetFair',
            sportId: sportId,
            competitionId: '*',
            eventId: eventId,
          },
        ];
        const response: AxiosResponse<any> = await SVLS_API.post(
          `/catalog/v2/sports-feed/sports/markets`,
          {
            providerId: 'BetFair',
            marketsCriteria: 'ALL',
            eventDetails: eventDetails,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          }
        );

        const eventData = response.data[0];
        if (eventData.eventId === '') {
          dispatch(fetchEventByCompetitionFaild());
        } else {
          updateTopicUrlsInStore(dispatch, eventData);
          const eData: EventDTO = {
            enabled: eventData.enabled,
            openDate: eventData.openDate,
            customOpenDate: eventData?.customOpenDate,
            sportId: eventData.sportId,
            competitionId: eventData.competitionId,
            competitionName: eventData.competitionName,
            eventId: eventData.eventId,
            eventName: eventData.eventName,
            marketId: eventData.marketId,
            providerName: eventData.providerName,
            enableBookmaker: eventData.markets?.enableBookmaker,
            enableFancy: eventData.markets?.enableFancy,
            status: eventData.status,
            markets: eventData.markets,
            forcedInplay:eventData.forcedInplay,
          };
          const payload = {
            eventData: eData,
            sportId: eData.sportId,
            competitionId: eData.competitionId,
            matchOddsData:
              eventData.markets && eventData.markets.matchOdds
                ? eventData.markets.matchOdds.find(
                    (mo) =>
                      mo.marketName === 'Match Odds' ||
                      mo.marketName.toLowerCase() === 'moneyline'
                  )
                : null,
            onRefresh: true,
          };
          dispatch(fetchEventByCompetitionSuccess(payload));

          // if (
          //   eData.sportId === '1' ||
          //   eData.sportId === '4' ||
          //   eData.sportId === 'soccer' ||
          //   eData.sportId === 'cricket'
          // ) {
            if(eventData?.markets?.matchOdds){
              for (let mo of eventData?.markets?.matchOdds) {
                if (
                  mo.marketName !== 'Match Odds' &&
                  mo.marketName.toLowerCase() !== 'moneyline'
                ) {
              const secMOPayload = {
                eventId: eventData.eventId,
                marketId: mo.marketId,
                matchOddsData: mo,
              };
              dispatch(updateSecondaryMatchOdds(secMOPayload));
            }
          }
        }
          // }
          // if (eData.sportId === '4' || eData.sportId === 'cricket') {
          const secMarketsPayload = {
            eventId: eventData.eventId,
            bookmakerOddsData:
              eventData.markets && eventData.markets.bookmakers
                ? eventData.markets.bookmakers
                : null,
            enableBookmaker: eventData.markets
              ? eventData.markets.enableBookmaker
              : null,
            sessionOddsData:
              eventData.markets && eventData.markets.fancyMarkets
                ? eventData.markets.fancyMarkets
                : null,
            enableFancy: eventData.markets ? eventData.markets.enableFancy : null,
            fancySuspended : eventData.markets
                  ? eventData.markets.fancySuspended
                  :false,
            fancyDisabled : eventData.markets
                  ? eventData.markets.fancyDisabled
                  :false,

          };
          dispatch(updateSecondaryMarkets(secMarketsPayload));
          // }
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
};
export const updateOddsfromWS = (payload) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: payload,
  };
};

export const updateSecondaryMarkets = (payload) => {
  return {
    type: UPDATE_SECONDARY_MARKETS,
    payload: payload,
  };
};
export const updateFancyMarkets = (payload) => {
  return {
    type: UPDATE_FANCY_MARKETS,
    payload: payload,
  };
};
export const updateBookMakerMarkets = (payload) => {
  // console.log("payload",payload)
  return {
    type: UPDATE_BOOKMAKER_MARKETS,
    payload: payload,
  };
};

export const updateSecondaryMatchOdds = (payload) => {
  return {
    type: UPDATE_SECONDARY_MATCH_ODDS,
    payload: payload,
  };
};

export const updateSuspendedMarkets = (payload) => {
  return {
    type: SUSPENDED_MARKETS,
    payload: payload,
  };
};

export const updateEventScorecard = (payload) => {
  return {
    type: UPDATE_SCORECARD,
    payload: payload,
  };
};

export const updateDisabledMarkets = (payload) => {
  return {
    type: DISABLED_MARKETS,
    payload: payload,
  };
};