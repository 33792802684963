export const SportsList = [
  { id: '4', name: 'Cricket', slug: 'cricket' },
  { id: '1', name: 'Football', slug: 'football' },
  { id: '2', name: 'Tennis', slug: 'tennis' },
  { id: '7', name: 'horserace', slug: 'horseracing' },
  { id: '4339', name: 'greyhound', slug: 'greyhoundracing' },
  { id: '7511', name: 'Baseball', slug: 'baseball' },
  { id: '7522', name: 'Basketball', slug: 'basketball' },
  { id: '99990', name: 'Binary', slug: 'binary' },
  { id: '2378961', name: 'politics', slug: 'politics' },
  { id: '99994', name: 'kabaddi', slug: 'kabaddi' },
];

const SportSlugById: { [key: string]: string } = {
  '1': 'soccer',
  '2': 'tennis',
  '4': 'cricket',
  '7': 'horseracing',
  '4339': 'greyhoundracing',
  '7511': 'baseball',
  '7522': 'basketball',
  '99990': 'binary',
  '2378961': 'politics',
  '99994':'kabaddi'
};

const SportNameById: { [key: string]: string } = {
  '1': 'Soccer',
  '2': 'Tennis',
  '4': 'Cricket',
  '7': 'Horse Racing',
  '4339': 'GreyHound Racing',
  '7511': 'Baseball',
  '7522': 'Basketball',
  '99990': 'Binary',
  '2378961': 'Politics',
  '99994': 'Kabaddi',

};

export const SportIdBySlug: { [key: string]: string } = {
  soccer: '1',
  football: '1',
  tennis: '2',
  cricket: '4',
  horseracing: '7',
  greyhoundracing: '4339',
  baseball: '7511',
  basketball: '7522',
  binary: '99990',
  politics: '2378961',
  kabaddi: '99994'
};

const SoccerBetLimitMarkets = [
  { key: 'over_0.0', name: 'MATCH ODDS', type: 'MATCH_ODDS' },
  { key: 'over_0.5', name: 'Over/Under 0.5 Goals', type: 'MATCH_ODDS' },
  { key: 'over_1.5', name: 'Over/Under 1.5 Goals', type: 'MATCH_ODDS' },
  { key: 'over_2.5', name: 'Over/Under 2.5 Goals', type: 'MATCH_ODDS' },
];

const CricketBetLimitMarkets = [
  { key: 'MATCH_ODDS', name: 'MATCH ODDS', type: 'MATCH_ODDS' },
  { key: 'BOOKMAKER', name: 'BOOKMAKER', type: 'BOOKMAKER' },
  { key: 'FANCY', name: 'FANCY', type: 'FANCY' },
];

const TennisBetLimitMarkets = [
  { key: 'MATCH_ODDS', name: 'MATCH ODDS', type: 'MATCH_ODDS' },
];

const BinaryBetLimitMarkets = [
  { key: 'FANCY', name: 'FANCY', type: 'FANCY' },
];

const MarketBetLimitMap = {
  '1': SoccerBetLimitMarkets,
  '2': TennisBetLimitMarkets,
  '4': CricketBetLimitMarkets,
  '7522': TennisBetLimitMarkets,
  '7511': TennisBetLimitMarkets,
  '7': TennisBetLimitMarkets,
  '4339': TennisBetLimitMarkets,
  '99994': TennisBetLimitMarkets,
  '99990': BinaryBetLimitMarkets,
  '2378961': TennisBetLimitMarkets,
};

type MarketBetLimitObj = {
  key: string;
  name: string;
  type: string;
};

export const SportsSortOrder = ['4', '1', '2', '7', '4339', '7511', '7522', '99990', '2378961','99994'];

export function getSportNameById(id: string) {
  return SportNameById[id] ? SportNameById[id] : id;
}

export function getSportSlugById(id: string) {
  return SportSlugById[id] ? SportSlugById[id] : id;
}

export function getSportIdBySlug(slug: string) {
  return SportIdBySlug[slug] ? SportIdBySlug[slug] : slug;
}

export function getMarketBetLimitMapByID(sport: string): MarketBetLimitObj[] {
  return MarketBetLimitMap[sport];
}
