import axios from 'axios';
import store from '../../store/store';
import { logout } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../models/RootState';
import { EnvUrlsMap, getBaseUrl } from '../../environment-urls/Environment-urls';


const TG_API = axios.create({
  baseURL:  getBaseUrl(process.env.REACT_APP_NODE_ENV, 'REACT_APP_TG_URL'),
  withCredentials: false,
  timeout: 10000, // 10 seconds
});

//Add a response interceptor
TG_API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('***********Unauthorized Error**********');
      const token = sessionStorage.getItem('jwt_token');
      if (token) {
        (store.dispatch as ThunkDispatch<RootState, void, AnyAction>)(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default TG_API;
