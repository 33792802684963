import { ExBetslip } from '../../models/RootState';
import { FETCH_OPEN_BETS_CACHE_SUCCESS, UPDATE_OPEN_BETS } from './exchBetslipActionTypes';

type Action = {
  type: string;
  payload: any;
};

const initialState: ExBetslip = {
  openBets: [],
  openBetsCache: [],
  totalOrders: 0,
};

const ExchBetslipReducer = (
  state = initialState,
  action: Action
): ExBetslip => {
  switch (action.type) {
    case FETCH_OPEN_BETS_CACHE_SUCCESS: {
      const openBets = action.payload.result;
      const totalOrders = action.payload.totalOrders;

      return {
        ...state,
        openBetsCache: openBets,
        totalOrders: totalOrders,
      };
    }
    case UPDATE_OPEN_BETS: {
      return {
        ...state,
        openBetsCache: [action.payload.result, ...state.openBetsCache].slice(0, 100),
        totalOrders: Math.min(state.totalOrders + 1, 100),
      }
    }
    default: {
      return state;
    }
  }
};

export default ExchBetslipReducer;
